<script setup lang="ts">
const AuthStore = useAuthStore()
const { username, isAuthenticated, email, getAuthString, modalState } = storeToRefs(AuthStore)

const items = computed(() => [
  [{
    label: username?.value || (email?.value ? email.value.split('@', 1)[0] : undefined) || 'Unknown',
    slot: 'account',
    // disabled: !isAuthenticated.value,
  }],
  [{
    label: 'Subscription',
    icon: 'i-heroicons-credit-card',
    click: () => navigateTo('/subscription/'),
    // disabled: !isAuthenticated.value,
  }],
  [{
    label: 'Profile',
    icon: 'i-heroicons-cog-8-tooth',
    shortcuts: ['E'],
    click: () => modalState.value = !modalState.value,
  }],
  [{
    label: 'Sign out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    shortcuts: ['S'],
    click: () => AuthStore.signOut(),
  }],
])
</script>

<template>
  <UModal v-model="modalState" :appear="false" :ui="{ container: 'flex min-h-full items-center justify-center text-center' }">
    <UNotifications :ui="{ position: 'inset-auto' }" />
    <AccountProfile />
  </UModal>
  <UDropdown v-if=" isAuthenticated" :items="items" :ui="{ item: { disabled: 'cursor-text select-text' } }" :popper="{ placement: 'bottom-end' }">
    <Gravatar :email="email" />
    <template #account="{ item }">
      <div class="text-left flex w-full justify-between items-center">
        <div>
          <p>
            Signed in as
          </p>
          <p class="truncate font-medium text-white">
            {{ item.label }}
          </p>
        </div>
        <div>
          <UBadge variant="outline" :ui="{ rounded: 'rounded-full' }">
            {{ getAuthString }}
          </UBadge>
        </div>
      </div>
    </template>
    <template #item="{ item }">
      <span class="truncate">{{ item.label }}</span>
      <UIcon dynamic :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-500 ms-auto" />
    </template>
  </UDropdown>
</template>
